// RacesList.jsx
import React, { useEffect, useState } from 'react';
import { Link as RouterLink} from 'react-router-dom';
import { Toolbar, AppBar, Grid, ListItemAvatar, Avatar, Divider, ListItemButton, Paper, Box, List, ListItem, ListItemText, useTheme, Typography } from '@mui/material';

const apiUrl = process.env.REACT_APP_API_URL;

type Race = {
  directory: string;
  name: string;
  date: string;
  status: 'Live' | 'Upcoming' | 'Complete';
  isExternal: string;
  url: string;
};

const RacesList = () => {
  const theme = useTheme();
  const [races, setRaces] = useState<Race[]>([]);

  useEffect(() => {
    fetch(`${apiUrl}/api/results/files`)
      .then(response => response.json())
      .then(data => setRaces(data.races.sort((a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime())));
  }, []);

  const liveAndUpcomingRaces = races.filter(race => race.status === 'Live' || race.status === 'Upcoming');
  const completedRaces = races.filter(race => race.status === 'Complete');

  const renderRaceItem = (race: Race) => {
    const isExternal = race.isExternal ===  'true';
    //console.log(race.url)
    return(
      <React.Fragment key={race.directory}>
        <ListItem disablePadding>
          <ListItemAvatar>
            <Avatar alt="Logo" src={`${apiUrl}/result-uploads/${race.directory}/logo.png`} sx = {{ height: '60px', width: '60px', borderRadius: '4px', img: { objectFit: 'contain' } }}/>
          </ListItemAvatar>
          <ListItemButton  sx={{
              '&:hover': {
                bgcolor: 'action.hover', // Use theme's action.hover color for hover background
              },
            }}
            component={isExternal ? 'a' : RouterLink} // Use 'a' for external links
            href={isExternal ? race.url : undefined} // Only set href for external links
            to={!isExternal ? `/race/${race.directory}/results.txt` : undefined} // Only set 'to' for internal navigation
          >
          <ListItemText 
            primary={
              <>
                <Typography component="span" variant="body1" sx={{ fontSize: '15px' }}>
                  {race.name}
                </Typography>
                <Typography component="span" variant="body1" sx={{ fontSize: '13px', fontStyle: 'italic' }}>
                  {" " + race.date}
                </Typography>
              </>
            } 
          />
          </ListItemButton>
        </ListItem>
        <Divider sx={{ opacity: 0.6 }}/> {/* Add a light divider after each item */}
      </React.Fragment>
      )
  };

  return (
    
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
      }}
    >
      <AppBar position="static">
        <Toolbar sx={{ backgroundColor: 'secondary' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
            {/* Placeholder for logo */}
            <img src={theme.palette.mode === 'light' ? process.env.PUBLIC_URL + '/logonew.png' : process.env.PUBLIC_URL + 'logonew-white.png'} alt="timing.kiwi logo" style={{ maxHeight: '120px', marginRight: theme.spacing(2), padding: theme.spacing(2) }} />
          <Typography variant = 'h4' >timing.kiwi</Typography>
          </Box>
        </Toolbar>
      </AppBar>
        <Paper sx={{ display: 'flex', justifyContent: 'space-around', padding: 2, margin: theme.spacing(2) }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Box sx={{ bgcolor: theme.palette.background.paper, margin: theme.spacing(1), padding: theme.spacing(1) }}>
                <Typography variant="h6" gutterBottom>Live & Upcoming</Typography>
                <List dense sx = {{bgcolor: 'background.paper'}}>
                  {liveAndUpcomingRaces.map(race =>
                    renderRaceItem(race)
                  )}
                </List>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box sx={{ bgcolor: theme.palette.background.paper, margin: theme.spacing(1), padding: theme.spacing(1) }}>
                <Typography variant="h6" gutterBottom>Completed</Typography>
                <List dense sx = {{bgcolor: 'background.paper'}}>
                  {completedRaces.slice(0).reverse().map(race =>
                    renderRaceItem(race)
                  )}
                </List>
              </Box>
            </Grid>
          </Grid>
        </Paper>
    </Box>
  );
};

export default RacesList;