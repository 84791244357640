
import React, {useEffect} from 'react';
import { Button, FormControl, InputLabel, MenuItem, Select, Typography, useTheme, useMediaQuery, SelectChangeEvent } from '@mui/material';
import { EventSelectionProps } from './types';
import '@fontsource/chakra-petch';
import '../styles/resultsstyles.css'

const EventSelection: React.FC<EventSelectionProps> = ({
    events,
    selectedEventId,
    selectedResultView,
    onSelectEvent,
    onResultViewChange,
    onSelectDivisionId,
    onGenderSelectionChange,
    genderOptions,
    selectedGender,
    selectedDivisionId,
    divisionOptions,
}) => {
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.up('md'))
    const tableButtonsRef = React.useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (selectedResultView === "Category Results" && !selectedDivisionId && divisionOptions.length > 0) {
            const firstDivisionId = divisionOptions[0].divisionId;
            const event: SelectChangeEvent<string> = { target: { value: firstDivisionId } } as SelectChangeEvent<string>;
            onSelectDivisionId(event);
            const firstGender = genderOptions[0];
            const gEvent: SelectChangeEvent<string> = { target: { value: firstGender } } as SelectChangeEvent<string>;
            onGenderSelectionChange(gEvent);
        }
    }, [divisionOptions, onSelectDivisionId, selectedDivisionId]);

    useEffect(() => {
        if (selectedResultView === "Gender Results" &&!selectedGender && genderOptions.length > 0) {
            const firstGender = genderOptions[0];
            const event: SelectChangeEvent<string> = { target: { value: firstGender } } as SelectChangeEvent<string>;
            onGenderSelectionChange(event);
        }
    }, [genderOptions, onGenderSelectionChange, selectedGender]);

    return (
        <div ref={tableButtonsRef} style={{ display: 'flex', flexDirection: 'column', alignItems: matches ? 'flex-start' : 'flex-end' }}>
            {/* Button container with flexbox to align buttons */}
            { matches ? (
            <div style = {{width: '100%'}}>
                <div style = {{display: 'flex', flexDirection: 'row', width: '100%'}}>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: theme.spacing(1) }}>
                    {Object.entries(events).map(([eventId, eventDetails]) => (
                    <Button 
                        key={eventId} 
                        variant="contained"
                        color={selectedEventId === eventId ? 'primary' : "secondary"}
                        style={{ marginBottom: '0.5rem' }} // Adjust as necessary
                        onClick={() => onSelectEvent(eventId)}
                    >
                        {eventDetails.eventName}
                    </Button>
                    ))}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 'auto'}}>
                    <Typography variant = 'h6' >{selectedEventId ? events[selectedEventId].eventName + " "+ selectedResultView : ""}</Typography>
                </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '1rem', gap: theme.spacing(1) }}>
                <Button onClick={() => onResultViewChange('Overall')} variant = 'contained' color={selectedResultView === 'Overall' ? 'primary' : 'secondary'} size="small">Overall</Button>
                <Button onClick={() => onResultViewChange('Gender Results')} variant = 'contained' color={selectedResultView === 'Gender Results' ? 'primary' : 'secondary' } size="small">Gender Results</Button>
                <Button onClick={() => onResultViewChange('Category Results')} variant = 'contained' color={selectedResultView === 'Category Results' ? 'primary' : 'secondary' } size="small">Category Results</Button>         
                </div>
            </div>
            ) : (
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                <div style={{ width: '48%', marginBottom: theme.spacing(0) }}>
                {/* Event Dropdown */}
                <FormControl variant="filled" fullWidth size="small" margin="dense">
                    <InputLabel>Event</InputLabel>
                    <Select
                    value={selectedEventId}
                    label="Event"
                    onChange={(e) => {
                        e.target.value ? onSelectEvent(e.target.value) : onSelectEvent("");
                    }}
                    >
                    {Object.entries(events).map(([eventId, eventDetails]) => (
                        <MenuItem key={eventId} value={eventId}>
                            {eventDetails.eventName}
                        </MenuItem>
                    ))}
                    </Select>
                </FormControl>
                </div>
                <div style={{ width: '48%', marginBottom: theme.spacing(0) }}>
                {/* Results View Dropdown */}
                <FormControl variant="filled" fullWidth size="small" margin="dense">
                    <InputLabel>Results View</InputLabel>
                    <Select
                    value={selectedResultView}
                    label="Results View"
                    onChange={(e) => onResultViewChange(e.target.value)}
                    >
                    <MenuItem value="Overall">Overall</MenuItem>
                    <MenuItem value="Gender Results">Gender Results</MenuItem>
                    <MenuItem value="Category Results">Category Results</MenuItem>
                    </Select>
                </FormControl>
                </div>
            </div>
            )}
            {/* Dropdown container to control its width */}
            {selectedEventId && selectedResultView === 'Category Results' && (
            <div className={matches ? "desktop-dropdowns" : "mobile-dropdowns"}>
                <div style={{ width: '48%', marginBottom: theme.spacing(0) }}>
                <FormControl variant="filled" fullWidth size="small" margin="dense">
                    <InputLabel id="gender-select-label">Gender</InputLabel>
                    <Select
                    labelId="gender-select-label"
                    id="gender-select"
                    value={selectedGender || ''}
                    onChange={onGenderSelectionChange}
                    label="Gender"
                    >
                    {genderOptions.map(gender => (
                        <MenuItem key={gender} value={gender}>{gender}</MenuItem>
                    ))}
                    </Select>
                </FormControl>
                </div>
                <div style={{ width: '48%', marginBottom: theme.spacing(0) }}>
                <FormControl variant="filled" fullWidth size="small" margin="dense" sx = {{ marginBottom: theme.spacing(0) }}>
                    <InputLabel id="division-select-label">Category</InputLabel>
                    <Select
                    value={selectedDivisionId || ''}
                    onChange={onSelectDivisionId}
                    label="Category"
                    >
                    {divisionOptions.map(division => (
                        <MenuItem key={division.divisionId} value={division.divisionId}>{division.divisionName}</MenuItem>
                    ))}
                    </Select> 
                </FormControl>
                </div>
            </div>
            )}
            {selectedEventId && selectedResultView === 'Gender Results' && (
            <div className={matches ? "desktop-dropdowns" : "mobile-dropdowns"}>
                <div style={{ width: '48%', marginBottom: theme.spacing(0) }}>
                <FormControl variant="filled" fullWidth size="small" margin='dense' sx={{ marginBottom: theme.spacing(0) }}>
                    <InputLabel id="gender-select-label">Gender</InputLabel>
                    <Select
                    labelId="gender-select-label"
                    id="gender-select"
                    value={selectedGender || ''}
                    onChange={onGenderSelectionChange}
                    label="Gender"
                    >
                    {genderOptions.map(gender => (
                        <MenuItem key={gender} value={gender}>{gender}</MenuItem>
                    ))}
                    </Select>
                </FormControl>
                </div>
            </div>
            )}
            </div>
    )
};

export default EventSelection;