import React, { useEffect, useState, useMemo } from 'react';
import {
  MRT_ColumnDef,
  type MRT_SortingState,
} from 'material-react-table';

import { AppBar, Toolbar, useTheme, Box, Typography, useMediaQuery, Paper } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { useParams } from 'react-router-dom';
import '@fontsource/chakra-petch';
import { Participant, Result, RaceInfo, PaginationState, Events, Split, Division } from './types';
import ResultsTable from './ResultsTable';
import EventSelection from './EventSelection';

const apiUrl = process.env.REACT_APP_API_URL;


const RaceResults = () => {
  const [data, setData] = useState<Result[]>([]);
  const [events, setEvents] = useState<Events>({}); 
  const [selectedEventId, setSelectedEventId] = useState<string | null>("");
  const [selectedDivisionId, setSelectedDivisionId] = useState<string | null>(null);
  const [selectedResultView, setSelectedResultView] = useState<string>('Overall');
  const [selectedGender, setSelectedGender] = useState<string | null>(null);
  const [columnOrder, setColumnOrder] = useState<string[]>([]);
  const [timingPoints, setTimingPoints] = useState<Set<string>>(new Set());
  const [raceInfo, setRaceInfo] = useState<RaceInfo | null>(null);
  const [logoURL, setLogoURL] = useState("");
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20, // Adjust based on your default page size
  });
  const [sorting, setSorting] = useState<MRT_SortingState>([{
    id: 'overallRank', desc: false
  }]);
  const selectedEventIdRef = React.useRef(selectedEventId);

  const tableInterfaceRef = React.useRef<HTMLDivElement>(null);
  
  const raceDir = useParams();
  
  const theme = useTheme();
  
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  
  // Update the ref whenever the state changes
  useEffect(() => {
    selectedEventIdRef.current = selectedEventId;
  }, [selectedEventId]);

  useEffect(() => {

    const resultsSource = new EventSource(`${apiUrl}/api/live?raceDir=` + raceDir.raceDir);

    resultsSource.onmessage = (event) => {

      const currentSelectedEventId = selectedEventIdRef.current;
      const parsedData = JSON.parse(event.data);
      setRaceInfo(parsedData.race);

      const newTimingPoints = new Set<string>();
      
      const processedResults: Result[] = parsedData.results
        .filter((result: any) => {
          const participant = parsedData.participants.find((p: Participant) => p.id === result.participantId);
          //return participant?.status !== 'Not started'; // Filter out 'Not Started'
          return participant;
        })
        .map((result: any) => {
          const participant = parsedData.participants.find((p: Participant) => p.id === result.participantId);
          if (participant) {
            participant.splits = parsedData.splits.filter((s: Split) => s.participantId === participant.id);
            participant.splits.forEach((split: Split) => {
              newTimingPoints.add(split.timingPoint);
            });
          }
          // Set finishTime to 'DNS' if status is 'DNS', else keep original finishTime
          let finishTime;
          let overallRank;
          if (participant?.status === 'DNS') {
            finishTime = 'DNS';
            overallRank = 'DNS';
          } else if (participant?.status === 'Not started') {
            finishTime = 'Not Started';
            overallRank = 'Not Started';
          } else {
            finishTime = result.finishTime.slice(0, -4);
            overallRank = result.overallRank;
          }
          return { 
            ...result, 
            name: participant ? participant.name : 'Unknown',
            bib: participant ? participant.bib : '',
            gender: participant ? participant.gender : '',
            genderId: participant ? participant.genderId : '',
            status: participant ? participant.status : '',
            division: participant ? participant.division : '',
            divisionId: participant ? participant.divisionId : '',
            splits: participant ? participant.splits : [],
            finishTime, // Adjusted finishTime
            overallRank, // Adjusted overallRank
          };
        });
      // Update state with aggregated timing points
      setTimingPoints(timingPoints => {
        const updatedTPoints = new Set(timingPoints);
        newTimingPoints.forEach(tp => updatedTPoints.add(tp));
        return updatedTPoints;
      });

      setLogoURL(`${apiUrl}/result-uploads/${raceDir.raceDir}/logo.png`);

      setData(processedResults || []);
      setEvents(parsedData.events || {});
      
      if (!currentSelectedEventId && Object.keys(parsedData.events).length > 0) {
        const firstEventId = Object.keys(parsedData.events)[0];
        setSelectedEventId(firstEventId);
      }

    }
    return () => {
      resultsSource.close();
    };
  }, [raceDir.raceDir]);
  
  const customSort = (rowA: any, rowB: any) => {
    const rankA = rowA.original.overallRank;
    const rankB = rowB.original.overallRank;
  
    // Handling non-numeric specific orders
    const order: { [key: string]: number } = { 'DNS': 1, 'Not Started': 2 };
  
    const getSortValue = (rank: number) => {
      if (!isNaN(Number(rank))) return { type: 'number', value: Number(rank) };
      return { type: 'string', value: order[rank] || 3 }; // Default to 3 for any other non-numeric values
    };
  
    const sortValueA = getSortValue(rankA);
    const sortValueB = getSortValue(rankB);
  
    // First, sort by type to ensure numbers come before strings
    if (sortValueA.type !== sortValueB.type) {
      return sortValueA.type === 'number' ? -1 : 1;
    }
  
    // Then, if both are numbers or both are strings, sort by their value
    return sortValueA.value - sortValueB.value;
  };

  type ColumnKey = 'overallRank' | 'bib' | 'name' | 'division' | 'gender' | 'finishTime' | 'genderRank' | 'catRank';

type ColumnConfig = {
    [key in ColumnKey]: MRT_ColumnDef<Result>;
}

// Extend MRT_ColumnDef for dynamic columns that might not match the fixed keys
interface ExtendedColumnDef extends MRT_ColumnDef<Result> {
    id: string;
}

interface ViewColumns {
    [view: string]: (ColumnKey | string)[];  // Include string to accommodate dynamically generated column IDs
}

const columns: MRT_ColumnDef<Result>[] = useMemo(() => {
    const columnConfig: ColumnConfig = {
        overallRank: { id: 'overallRank', accessorKey: 'overallRank', header: 'Rank', size: 20, grow: false, sortingFn: customSort },
        bib: { id: 'bib', accessorKey: 'bib', header: 'Bib', size: 20, grow: false },
        name: { id: 'name', accessorKey: 'name', header: 'Name', size: 60, grow: false },
        division: { id: 'division', accessorKey: 'division', header: 'Category', size: 60, grow: false },
        gender: { id: 'gender', accessorKey: 'gender', header: 'Gender', size: 50, grow: false },
        finishTime: { id: 'finishTime', accessorKey: 'finishTime', header: 'Finish', size: 40, grow: false },
        genderRank: { id: 'genderRank', accessorKey: 'genderRank', header: 'Rank', size: 20, grow: false, sortingFn: customSort },
        catRank: { id: 'catRank', accessorKey: 'catRank', header: 'Rank', size: 20, grow: false, sortingFn: customSort }
    };

    const excludedPoints = ['Start', 'Commentator', 'Finish'];

    const splitColumns = Array.from(timingPoints)
      .filter(timingPointName => !excludedPoints.includes(timingPointName))
      .map(timingPointName => ({
        id: timingPointName,
        accessorFn: (row: Result) => row.splits?.find(s => s.timingPoint === timingPointName)?.splitTime.slice(11,19) || '',
        header: timingPointName,
        minSize: 10,
        size: 20,
        grow: false,
      }));

    const viewColumns: ViewColumns = {
        'Overall': ['overallRank', 'bib', 'name', 'division', 'gender', 'finishTime', ...splitColumns.map(c => c.id)],
        'Gender Results': ['genderRank', 'bib', 'name', 'finishTime', ...splitColumns.map(c => c.id)],
        'Category Results': ['catRank', 'bib', 'name', 'finishTime', ...splitColumns.map(c => c.id)]
    };

    const selectedColumns = viewColumns[selectedResultView] || [];
    const cols = selectedColumns.map(key => {
        // This handles both fixed and dynamic columns
        return columnConfig[key as ColumnKey] || splitColumns.find(c => c.id === key);
    }).filter(Boolean);

    setColumnOrder(['mrt-row-expand', ...selectedColumns]);
    return cols;
}, [selectedResultView, timingPoints]);

  // Generating unique gender options from the data
  const genderOptions = useMemo<string[]>(() => {
    // Map to '' if gender is undefined
    const allGenders = new Set(data.map(result => result.gender || ''));
    return Array.from(allGenders);
  }, [data]);

  const divisionOptions: Division[] = useMemo(() => {
    if (selectedEventId && events[selectedEventId]) {
      return Object.values(events[selectedEventId].divisions || {});
    }
    return [];
  }, [data, selectedEventId, events]);

  const handleEventSelection = (eventId: string) => {
    setSelectedEventId(eventId);
    setSelectedDivisionId(null); // Reset division selection when changing events
  };

  const handleDivisionSelection = (event: SelectChangeEvent<string>): void => {
    // Extract the value from the event. If the value is an empty string, convert it to null (if your logic requires handling null).
    const divisionId = event.target.value !== '' ? event.target.value : null;
    // Assuming setSelectedDivisionId is your state setter that expects a string or null:
    setSelectedDivisionId(divisionId);
  };

  const handleResultViewSelection = (view: string) => {
    setSelectedResultView(view);
    if(view === 'Category Results'){
      setSorting([{id: 'catRank', desc: false }]);
    } else if (view === 'Gender Results'){
      setSorting([{ id: 'genderRank', desc: false }])
    } else {
      setSorting([{ id: 'overallRank', desc: false }])
    }
    if(view !== 'Category Results') {
      setSelectedDivisionId(null);
    }
    if(view !== 'Gender Results') {
      setSelectedGender(null);
    }
  }
  // Handler for gender selection
  const handleGenderSelection = (event: SelectChangeEvent<string>) => {
    setSelectedGender(event.target.value);
  };



  const filteredData = useMemo(() => {
    let filteredResults = data;
    if (selectedEventId) {
      filteredResults = filteredResults.filter(result => result.eventId === selectedEventId);
    }
    if (selectedDivisionId && selectedEventId) {
      filteredResults = filteredResults.filter(result => result.division === events[selectedEventId]?.divisions[selectedDivisionId]?.divisionName);
      filteredResults = filteredResults.filter(result => selectedGender === 'All' || '' ? result.gender : result.gender === selectedGender);
    }
    if (selectedEventId && selectedGender) {
      filteredResults = filteredResults.filter(result => selectedGender === 'All' || '' ? result.gender : result.gender === selectedGender);
    }
    return filteredResults;
  }, [data, selectedEventId, selectedDivisionId, selectedGender, events]);
  
  //const divisionOptions = selectedEventId ? Object.values(events[selectedEventId]?.divisions || {}) : [];
  //const selectedEventName = selectedEventId ? events[selectedEventId]?.eventName : '';

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <AppBar position="static">
        <Toolbar sx={{ backgroundColor: 'secondary' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
            {/* Placeholder for logo */}
            <img src={logoURL} alt="Event Logo" style={{ height: '100px', maxHeight: '100px', marginRight: theme.spacing(2), padding: theme.spacing(2) }} />
            <Typography variant="h6">
              {raceInfo && raceInfo.raceName}
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
      <Paper elevation={5} 
        component="main"
        ref={tableInterfaceRef}
        sx={{
          display: 'flex',
          flexGrow: 1,
          flexDirection: 'column',
          marginTop: theme.spacing(1), // Adjusts the outside margin
          marginBottom: {
            xs: theme.spacing(2),
            sm: theme.spacing(1),
            md: theme.spacing(1)
          },
          marginRight: theme.spacing(1),
          marginLeft: theme.spacing(1),
          padding: theme.spacing(0), // Adds padding inside the box
          borderRadius: '10px', // Optional: adds rounded corners
          backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.background.paper,
          height: {
            // xs: `calc(100% - 176px)`, // Smaller devices might have more elements visible
            // md: `calc(100% - 176px)`, // Adjusted value for larger devices
          }
      }}>
        <div style={{ display: 'flex', flexDirection: 'column', padding: theme.spacing(1) }}>
          <div>
            {/* Flex container for buttons and dropdown */}
            <EventSelection
              events={events}
              selectedEventId={selectedEventId}
              selectedResultView={selectedResultView}
              onSelectEvent={handleEventSelection}
              onResultViewChange={handleResultViewSelection}
              onSelectDivisionId={handleDivisionSelection}
              onGenderSelectionChange={handleGenderSelection}
              genderOptions={genderOptions}
              divisionOptions={divisionOptions}
              selectedGender={selectedGender}
              selectedDivisionId={selectedDivisionId}
            />
          </div>
          <Box sx={{ flexGrow: 1, overflowY: 'auto', mt: theme.spacing(1) }}>
          <ResultsTable
              columns={columns}
              data={filteredData}
              sorting={sorting}
              onSortingChange={setSorting}
              pagination={pagination}
              onPaginationChange={setPagination}
              columnOrder={columnOrder}
              selectedResultView={selectedResultView}
              events={events}
            />
          </Box>
        </div>
      </Paper>
    </Box>
  );
  
};

export default RaceResults;
