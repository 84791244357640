import { createTheme } from '@mui/material/styles';
import { lightGreen, blueGrey } from '@mui/material/colors'

const mainTheme = (mode: 'light' | 'dark') => createTheme({
  palette: {
    mode,
    ...(mode === 'light')
    ? {
      primary: lightGreen,
      secondary: blueGrey
    }
  : {
      primary: lightGreen,
    }
  },
  typography: {
    h6: {
      fontFamily: 'Chakra Petch, sans-serif'
    },
    h4: {
      fontFamily: 'Chakra Petch, sans-serif'
    }
  },
  components: {
    MuiTableHead: {
      styleOverrides: {
        root: {
          wordBreak: 'break-word',
          whiteSpace: 'normal'
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        head: { // Target table header cells
          fontSize: '0.75rem', // Smaller font size
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: '1rem', // Smaller icons
        },
      },
    },
  }
});

export default mainTheme


