import React from 'react';
import { MaterialReactTable } from 'material-react-table';
import { Box, Paper, Typography, useTheme } from '@mui/material';
import {
    useMaterialReactTable,
    MRT_ColumnDef,
  } from 'material-react-table';
import { Result, Events } from './types'

interface ResultsTableProps {
    columns: MRT_ColumnDef<Result>[];
    data: Result[];
    sorting: any; // Define a more specific type for sorting if available
    onSortingChange: (newSorting: any) => void; // Adjust the type as needed
    pagination: any; // Define a specific type for pagination
    onPaginationChange: (newPagination: any) => void; // Adjust the type as needed
    columnOrder: string[];
    selectedResultView: string;
    events: Events;
}

const ResultsTable: React.FC<ResultsTableProps> = ({
    columns,
    data,
    sorting,
    onSortingChange,
    pagination,
    onPaginationChange,
    columnOrder,
    selectedResultView,
    events,
  }) => {
    const theme = useTheme();
  
    const table = useMaterialReactTable<Result>({
        columns,
        data,
        enableExpandAll: true,
        enableExpanding: true,
        enableRowSelection: false,
        enableStickyHeader: true,
        enablePagination: true,
        enableRowVirtualization: false, // Disable pagination for virtualization
        autoResetPageIndex: false,
        onSortingChange,
        layoutMode: 'semantic',
        initialState: {
          density: 'compact',
        },
        muiPaginationProps: {
          color: 'primary',
          shape: 'rounded',
          variant: 'text',
          size: 'small',
          siblingCount: 1
        },
        muiTableContainerProps: {
          sx: {
            maxHeight: {
              xs: '1000px',  // Applies on extra-small devices (mobile)
              sm: '1000px',  // Overrides for small devices if different value is needed
              md: 'calc(100vh - 180px)',  // Applies from medium devices (tablets) upwards
              lg: 'calc(100vh - 180px)',  // Applies from large devices upwards
              xl: 'calc(100vh - 180px)'   // Applies from extra-large devices upwards
            },
            overflowY: 'auto',
            overflowX: 'auto',
          }
        },
        paginationDisplayMode: 'pages',
        onPaginationChange,
        state: {
          columnOrder,
          pagination,
          sorting
        },
    
        muiExpandButtonProps: ({ row, table }) => ({
          onClick: () => {
            table.setExpanded({ [row.id]: !row.getIsExpanded() })
          }, //only 1 detail panel open at a time
          sx: {
            transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
            transition: 'transform 0.2s',
          },
        }),
        renderDetailPanel: ({ row }) =>
          row.original.overallRank ? (
            <Paper 
            elevation = {3}  
            sx = {{
              paddingLeft: theme.spacing(2),
              paddingRight: theme.spacing(2),
              paddingTop: theme.spacing(1),
              paddingBottom: theme.spacing(1),
              backgroundColor: theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.grey[900]
            }}>
              <Box
              sx={{
                display: 'grid',
                margin: 'auto',
                gridTemplateColumns: '1fr',
                width: '100%',
              }}
              >
                <Typography variant="body2">Overall Rank: {row.original.overallRank} / {row.original.divisionId ? events[row.original.eventId]?.divisions[row.original.divisionId]?.totalEventFinished : null}</Typography>
                <Typography variant="body2">Gender Rank: {row.original.genderRank} / {(row.original.divisionId && row.original.genderId) ? events[row.original.eventId]?.divisions[row.original.divisionId]?.genders[row.original.genderId].totalEventGenderFinished : null}</Typography>
                <Typography variant="body2">Category Rank: {row.original.catRank} / {(row.original.divisionId && row.original.genderId) ? events[row.original.eventId]?.divisions[row.original.divisionId]?.genders[row.original.genderId].finished : null}</Typography>
              </Box>
            </Paper> 
          ) : null,
        
        displayColumnDefOptions: {
          'mrt-row-expand': {
            size: 50, //adjust the size of the row select column
            grow: false, //new in v2.8 (default is false for this column)
          },
        },
        
        muiTableHeadCellProps: { 
          sx: { 
            fontSize: '10px', fontWeight: 'bold', wordBreak: 'normal', whiteSpace: 'normal',
            paddingRight: theme.spacing(0),
            paddingLeft: theme.spacing(0), 
          } 
        },
        muiTableBodyRowProps: ({ row }) => {
          return { 
            onClick: (event) =>{
              table.setExpanded({ [row.id]: !row.getIsExpanded() })
            },
            sx: {
              ...((row.original.overallRank <= 3 && row.original.overallRank >=1 && selectedResultView === 'Overall' ) ||
              (row.original.catRank <= 3 && row.original.overallRank >=1 && selectedResultView ==="Category Results") ||
              (row.original.genderRank <= 3 && row.original.overallRank >=1 && selectedResultView ==="Gender Results") ? {
                  backgroundColor: theme.palette.mode === 'light' ? theme.palette.primary.light : theme.palette.secondary.dark
                } : {}),
              cursor: 'pointer',  
            },
          };
        },
        muiTablePaperProps: ({ table }) => ({
          style: {
            zIndex: table.getState().isFullScreen ? "1200" : undefined,
          },
        }),
        muiTableBodyCellProps: ({cell}) => {
          return { 
            sx: {
              fontSize: '12px',
            }
            };
        },
    });
  
    return (
      <Box sx={{ width: '100%', overflowX: 'auto' }}>
        <MaterialReactTable table={table} />
      </Box>
    );
  };

export default ResultsTable;