import React, { createContext, useMemo, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import mainTheme from './styles/maintheme';
import RacesList from './RacesList';
import Link from '@mui/material/Link'
import RaceResults from './RaceResults/RaceResults';
import './styles/resultsstyles.css';

const ColorModeContext = createContext({ toggleColorMode: () => {} });

const App = () => {
  const [mode, setMode] = useState<'light' | 'dark'>('light');
  const colorMode = useMemo(() => ({
    toggleColorMode: () => {
      setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
    },
  }), []);

  const theme = useMemo(() => mainTheme(mode), [mode]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh'
          }}
        >
          <CssBaseline />
          <Box sx={{ flexGrow: 1 }}>
            <Router>
              <Routes>
                <Route path="/race/:raceDir/results.txt" element={<RaceResults />} />
                <Route path="/" element={<RacesList />} />
              </Routes>
            </Router>
          </Box>
          {/* Content above */}

          <AppBar sx={{  position: 'static', top: 'auto', bottom: 0, boxShadow: 'none', color: 'inherit' }}>
            <Toolbar variant="dense">
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                {/* Left side content if any */}
              </Typography>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: 'right' }}>
                <Link href = "/" color= 'inherit' underline='none'>timing.kiwi</Link>
              </Typography>
              <IconButton sx={{ ml: 1 }} onClick={colorMode.toggleColorMode} color="inherit">
                {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
              </IconButton>
            </Toolbar>
          </AppBar>
        </Box>

      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default App;
